import { productFruits } from "product-fruits"
import { userLang } from "./helpers/globals"
import { router, usePage } from "@inertiajs/vue3"

const initializeUserOnboarding = (data) => {
  if (window.productFruits) return
  const workspaceCode = import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE
  if (!workspaceCode) return

  // if user is defined and authenticated then initialize product fruits
  if (data.user?.email) {
    productFruits.init(workspaceCode, userLang, getUserData(data))
    return
  }
  // else add a route event listener so that when the user gets logged in, only then we initialize product fruits and then remove the event listener. We need to add a route event listner because the user can try to go to a different page and he might be redirected to login page.
  // When we register an event listener using 'router.on', Inertia automatically returns a callback that can be invoked to remove the event listener.
  let removeProductFruitEventListener = router.on("success", () => {
    const pageProps = usePage().props
    const user = pageProps?.auth?.user
    const tenant = pageProps?.tenant
    const themeEnabled = pageProps?.theme?.enabled
    const subscription = pageProps?.tenant_subscription?.tier?.name

    const userData = getUserData({
      user, tenant, subscription, themeEnabled
    })
    if (!userData.email) return
    if (!window.productFruits) {
      productFruits.init(workspaceCode, userLang, userData)
    }
    // remove route event listener
    removeProductFruitEventListener()
  })
}
const getUserData = ({ user, tenant, subscription, themeEnabled }) => {
  if (!user || !tenant) return {}
  let userRole = "non-ordering-user"
  if (user.permissions?.includes("admin-permission")) {
    userRole = "admin"
  } else if (
    user.permissions?.includes("order") ||
    user.permissions?.includes("order-for-all") ||
    user.permissions?.includes("order-for-entity")
  ) {
    userRole = "ordering-user"
  }
  const data = {
    username: user.email,
    language: userLang,
    email: user.email,
    firstname: user.first_name,
    lastname: user.last_name,
    signUpAt: user.created_at,
    role: userRole,
    props: {
      tenant_id: tenant.id,
      tenant_fqdn: tenant.fqdn,
      permissions: user.permissions,
      createdOwnTenant: tenant.created_own_tenant,
      white_label: themeEnabled,
      subscription: subscription,
    },
  }
  return data
}
export default initializeUserOnboarding
